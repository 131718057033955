/*
.image-grid {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
  }
  .image-grid:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(180deg, #f00, #000);
      mix-blend-mode: multiply;
      opacity: 0;
      transition: 0.5s;
  }
  .image-grid:before
  .image-grid:hover{
    z-index: 10;
    transform: scale(1.25);
    box-shadow: 0 25px 40px rgba(0,0,0,.5);
  }
  .grid-image {
    display: block;
    position: relative;
    flex-basis: 100%;
    padding: 0;
    box-sizing: border-box;
  }

.displayContent{
    display: block;
}

.hidden{
    display: none;
}
*/
/* increase images per line as browser gets larger using media queries
     this first query makes two images per line at 640px width */
/* three images per line when browser width exceeds 960px */
/* four images per line when browser width exceeds 1280px */
/* five images per line when browser width exceeds 1600px */

.image-grid {
	position: relative;
	width: 100%;
	display: flex;
    flex-wrap: wrap;
    justify-content: center;
	.box {
		position: relative;
		width: 275px;
		height: 275px;
		overflow: hidden;
		transition: transform 1s;
		transition: all .7s ease-in-out bac;
		animation-timing-function: cubic-bezier(0.45, 0.25, 0.60, 0.95);
		animation-direction: alternate;
		animation-duration: 0.5s;
		&:hover {
			z-index: 1;
			transform: scale(1.25);
			box-shadow: 0 25px 40px rgba(0,0,0,.5);
			.grid-image {
				&:before {
					opacity: 1;
				}
			}
			.display-content {
				h2 {
					transform: translateY(0);
					transition-delay: 0.4s;
				}
				p {
					transform: translateY(0);
					transition-delay: 0.4s;
				}
				button {
					transform: translateY(0);
					transition-delay: 0.4s;
				}
			}
		}
		.grid-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background: linear-gradient(180deg, #1e3c72, #2a5298);
				mix-blend-mode: multiply;
				opacity: 0;
				transition: 0.5s;
			}
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.display-content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: flex;
			flex-direction: column;
			padding: 20px;
			align-items: center;
			justify-content: flex-end;
			h2 {
				text-transform: uppercase;
				margin-bottom: 5px;
				font-size: 20px;
				transition: 0.5s;
				transform: translateY(200px);
			}
			p {
				transition: 0.5s;
				transform: translateY(200px);
			}
			button {
				transition: 0.5s;
				transform: translateY(200px);
			}
		}
	}
}
.hidden {
	display: none;
}
@media only screen and (min-width: 640px) {
	.box {
		flex-basis: 33.333%;
    }
    .image-grid{
        justify-content: center;
    }
}
@media only screen and (min-width: 960px) {
	.box {
		flex-basis: 25%;
	}
}
@media only screen and (min-width: 1280px) {
	.box {
		flex-basis: 25%;
	}
}
@media only screen and (min-width: 1600px) {
	.grid-image {
		flex-basis: 20%;
	}
}