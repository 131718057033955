:root {
  --primary-color: #34feaa;
  --bg-color: #1e1e1e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
}

body {
  background-color: var(--bg-color);
}

li {
  list-style: none;
}

a,
u {
  text-decoration: none !important;
}

section {
  margin-top: 1.2em;
}

section h2 {
  text-align: center !important;
}


/* Navbar Start */

#openMenu{
  display: none;
  float: right;
  top: -0.8rem;
  position: relative;
  margin-right: 0.8rem;
  font-size: 1.8rem;
  z-index: 1; 
}

.header{
  display: grid;
  grid-template-areas: "logo nav";
}

#navbar{
  grid-area: nav;
  display: grid;
  grid-template-columns: repeat(8, auto);
  align-items: center;
  justify-items: center;
}

.burger{
  display: none;
}

/*
#navbar{
  padding-right: 11%;
}
#navbar ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#navbar ul li {
  padding: 0.8rem;
}

#navbar ul li a {
  color: #fff;
}

#navbar ul li a:hover {
  color: var(--primary-color);
  cursor: pointer;
}
*/
/* Navbar End */


/* Hero section Start */

#hero {
  height: 100vh;
  padding-top: 3.2rem;
}

#card,
.card-project {
  background-color: transparent;
}

.header-description p {
  color: var(--primary-color);
}

.descriptionTyped span{
  color: var(--primary-color);
}

#card .card-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: none;
}

.profile-img {
  width: 200px;
  height: 200px;
  background-image: url("./images/profile.JPG");
  background-position: center;
  border-radius: 50%;
  background-size: cover;
}

.social-icons {
  font-size: 1.6rem;
}
.btn:focus {
  box-shadow: none;
}
.hero-btn {
  border: 1px solid var(--primary-color);
  font-weight: 1rem;
  background-color: transparent;
  margin: 0.8rem;
  padding: 0.8rem 1.6rem;
  color: #fff;
}
.hero-btn:active, .hero-btn:focus{
  outline: none;
}
#download {
  background-color: var(--primary-color);
  color: var(--bg-color);
}

.hero-btn:hover {
  background-color: var(--primary-color);
  animation-duration: 0.2s;
  color: var(--bg-color) !important;
}


/*Scrool down mouse*/

.scroll-down {
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-down span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 5px solid var(--primary-color);
  border-right: 5px solid var(--primary-color);
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.scroll-down span:nth-child(2) {
  animation-delay: -0.2s;
}

.scroll-down span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}


/* Hero section End */


/* Education section start */

.timeline a {
  color: var(--primary-color);
  cursor: default;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline>li {
  margin: 20px 0;
  padding-left: 3.2rem;
}

ul.timeline>li:before {
  content: ' ';
  background: var(--primary-color);
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid transparent;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}


/* Education section end */

span .fa {
  padding: 0.2rem;
}

.fa:hover {
  color: var(--primary-color);
}

.technologies {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.technologies-row {
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  text-align: start;
}

.technologies-row li::before{
  content: "";
  height: 0;
  width: 0;
  border-color: transparent var(--primary-color);
  border-style: solid;
  border-width: 0.5em 0 0.5em 0.5em;
  position: relative;
  display: block;   
  left: -1em;
  top: 1.25em;  
}


/* Scroll to Rop */

.scroll-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 99;
  outline: none;
  background-color: #27ae60;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  text-decoration: none;
}


/* End Scroll to Rop */


/* Projects Start */

#filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

#filter button {
  display: inline-block;
    padding: 8px 5px;
    background-color: #313131;
    color: #999;
    border-width: 1px 1px 2px 1px;
    border-style: solid;
    border-color: #222;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: MontSerrat,Tahoma,Arial,sans-serif;
    font-weight: 700;
    font-size: .75em;
    line-height: 1em;
    box-shadow: 1px 1px 0 rgba(0,0,0,.25);
    margin: 3px;
    margin-bottom: 0.8rem;
    opacity: 1;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

#filter button:hover {
  border-color: var(--primary-color);
  color: var(--bg-color);
  background-color: var(--primary-color);
}

#my-projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-project {
  padding: 1rem;
}

.card-project p {
  color: var(--bg-color);
}

.project-body {
  background: hsl(0, 0%, 97%);
}

.tags {
  padding: 0;
  list-style: none;
}

.tags li {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: var(--bg-color);
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .14);
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
}

#title{
  color: #fff;
}
#title:hover{
  color: #1e1e1e;
}

/* Projects End */


/* Conatct */

#contact-me {
  /*background-color: var(--primary-color);*/
  padding: 2em;
  background-image: linear-gradient(to right, var(--primary-color) 0%, #43e97b 100%);
}

.contact {
  margin: 0;
  width: 100%;
}

.contact-details {
  float: left;
  width: 60%;
  color: #5CDB95;
}

.contact-form {
  overflow: hidden;
  width: 40%;
}

.contact-form button {
  background-color: var(--primary-color);
  ;
}

.contact-details{
  color: rgb(30, 30, 30);
}

.contact-details .fa, .contact-details span{
  color: rgb(30, 30, 30);
}
.contact-infos{
  display: flex;
    flex-direction: column;
    align-items: center;
}

/* Certifications section start */

#image-certifs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.certifs {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

/* Certifications section end */


/* Footer */

#footer {
  margin-top: 0;
  padding: 1em;
  background-color: #34495e;
}

#footer p {
  color: var(--bg-color);
}


/* End Footer */

@media only screen and (max-width: 768px) {
  #openMenu{
    display: block;
    float: right;
    top: 0.6rem;
    position: relative;
    margin-right: 0.8rem;
    font-size: 1.8rem;
    z-index: 1; 
  }
  
  /*
  #navbar{
    padding: 0;
    display: none;
  }
  
  #navbar ul{
    display: flex;
    height: 100vh;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    width: 100%;
    transform: translateY(0%);
    transition: all 0.5s;
  }
  #navbar ul li:hover{
    background-color: var(--primary-color);
  }
  #navbar ul li a{
    text-transform: uppercase;
    font-size: 1.2rem;
  }
  #navbar ul li:hover a{
    color: var(--bg-color);
}
*/
  #hero {
      height: auto;
  }
  .scroll-down {
      display: none;
  }
  .contact {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .contact-details,
  .contact-form {
      width: 100%;
  }
}